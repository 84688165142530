<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addContentBlocksModal' hide-footer>
        <addContentBlocks @closeAddContentBlocks='toggleAddContentBlocks()' @addContentBlocksSuccess='addContentBlocksSuccess()'></addContentBlocks>
      </b-modal>
      <b-modal ref='editContentBlocksModal' hide-footer>
        <editContentBlocks
          :editingContentBlocks='currentContentBlocks'
          @closeEditContentBlocks='toggleEditContentBlocks()'
          @editContentBlocksSuccess='editContentBlocksSuccess()'
        ></editContentBlocks>
      </b-modal>
      <div class='row'>
        <h2>ContentBlocks</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddContentBlocks()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addContentBlocks from '../../components/addContentBlocks';
import editContentBlocks from '../../components/editContentBlocks';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  mounted(){
    //this.section = this.$route.params.section;
    this.section = this.$route.params.section;
        this.filterItems();
  },
  data() {
    return {
      section: null,
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Heading',
                field: 'Heading',
                sort: 'asc',
              },
              {
                label: 'Copy',
                field: 'Copy',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'ImageMeta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'FullWidth',
                field: 'FullWidth',
                sort: 'asc',
              },
              {
                label: 'Location',
                field: 'Location',
                sort: 'asc',
              },
              {
                label: 'DisplayOrder',
                field: 'DisplayOrder',
                sort: 'asc',
              },
              {
                label: 'Link',
                field: 'Link',
                sort: 'asc',
              },
              {
                label: 'LinkFriendly',
                field: 'LinkFriendly',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      allItems: [],
      addContentBlocksOpen: false,
      editContentBlocksOpen: false,
      currentContentBlocks: {}
    };
  },
  components: {
    addContentBlocks,
    editContentBlocks,
    Datatable,
  },
  created: function() {
    this.getContentBlocks();
    this.addContentBlocksOpen = false;
    this.editContentBlocksOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    filterItems(){
      console.log('FILTER', this.section)
      switch (this.section)
      {
        case "Home":
          this.items = this.allItems.filter(f => f.Location == "Home");
          break;
        case "CorporateSales":
          this.items = this.allItems.filter(f => f.Location == "CorporateSales");
          break;
        case "OwnerServices":
          
          this.items = this.allItems.filter(f => f.Location == "Warranty" || f.Location == "RoadsideAssistance" || f.Location == "HARP" || f.Location == "HARP_Content"|| f.Location == "HARP_Outro" || f.Location == "HARP_Intro");
          break;
        case "PartsAndAccessories":
          this.items = this.allItems.filter(f => f.Location == "GenuineParts" || f.Location == "GenuineAccessories");
          break;
        default:
          this.items = this.allItems;
          break;
          
      }
    },
    getContentBlocks() {
      var payload = {
        success: response => {
          this.allItems = response.data;
          this.filterItems();
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getContentBlocks', payload);
    },
    editItem(ContentBlocks) {
      this.toggleEditContentBlocks();
      this.currentContentBlocks = ContentBlocks;
    },
    toggleAddContentBlocks() {
      if(this.addContentBlocksOpen)
      {
        this.$refs.addContentBlocksModal.hide()
      }
      else{
        this.$refs.addContentBlocksModal.show()
      }
      this.addContentBlocksOpen = !this.addContentBlocksOpen;
    },
    addContentBlocksSuccess() {
      this.toggleAddContentBlocks();
      miniToastr['success']('ContentBlocks Added', 'Success', 1000, null);
      this.getContentBlocks();
    },
    toggleEditContentBlocks() {
      if(this.editContentBlocksOpen)
      {
        this.$refs.editContentBlocksModal.hide()
      }
      else{
        this.$refs.editContentBlocksModal.show()
      }
      this.editContentBlocksOpen = !this.editContentBlocksOpen;
    },
    editContentBlocksSuccess() {
      this.toggleEditContentBlocks();
      miniToastr['success']('ContentBlocks Edited', 'Success', 1000, null);
      this.getContentBlocks();
    }
  },
  watch:{
    $route (to, from){
        this.section = this.$route.params.section;
        this.filterItems();
        this.addContentBlocksOpen = false;
        this.editContentBlocksOpen = false;
    }
  } 
};
</script>
<style scoped>
.ContentBlocksThumbnail {
  height: 50px;
}
</style>
