<template>
  <div class="container">
    <h2>Add A ContentBlocks</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <div class="row">
      <div class="col-md-4">
        <label class="" for="Heading">Heading</label
        ><input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="newContentBlocks.Heading"
          placeholder="Heading"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Copy">Copy</label
        ><textarea
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="newContentBlocks.Copy"
          placeholder="Copy"
          tabindex="0"
          type="text"
          value=""
        ></textarea>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class for="Image">Image</label>
        <div v-if="!editingImage">
          <img
            v-bind:src="getImageSource(newContentBlocks)"
            class="previewImage"
          />
          <button @click="editImage()" type="button" class="btn btn-success">
            Update Image
          </button>
        </div>
        <div v-if="editingImage">
          <button @click="updateImage()" type="button" class="btn btn-success">
            Update
          </button>
          <button @click="editImage()" type="button" class="btn btn-warning">
            Cancel
          </button>
          <FileUploader
            @ImageDataChanged="updateImageData"
            @ImageURLDataChanged="updateImageUrl"
          />
        </div>
      </div>
      <div class="col-md-4">
        <label class="" for="ImageMeta">ImageMeta</label
        ><input
          class="form-control"
          id="ImageMeta"
          name="ImageMeta"
          v-model="newContentBlocks.ImageMeta"
          placeholder="ImageMeta"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="FullWidth">FullWidth</label>
        <br />
        <b-form-checkbox v-model="newContentBlocks.FullWidth"></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Location">Location</label
        ><input
          class="form-control"
          id="Location"
          name="Location"
          v-model="newContentBlocks.Location"
          placeholder="Location"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="DisplayOrder">DisplayOrder</label
        ><input
          class="form-control"
          id="DisplayOrder"
          name="DisplayOrder"
          v-model="newContentBlocks.DisplayOrder"
          placeholder="DisplayOrder"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Link">Link</label
        ><input
          class="form-control"
          id="Link"
          name="Link"
          v-model="newContentBlocks.Link"
          placeholder="Link"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="LinkFriendly">LinkFriendly</label
        ><input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="newContentBlocks.LinkFriendly"
          placeholder="LinkFriendly"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="LastUpdated">LastUpdated</label
        ><input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="newContentBlocks.LastUpdated"
          placeholder="LastUpdated"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <br />
    </div>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  name: "addContentBlocks",
  created: function () {},
  data() {
    return {
      newContentBlocks: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    FileUploader,
  },
  methods: {
    toggleView: function () {
      this.$emit("closeAddContentBlocks");
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    save: function () {
      var payload = {
        data: this.newContentBlocks,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("addContentBlocksSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("addContentBlocks", payload);
    },
  },
};
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
